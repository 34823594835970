<template>
  <el-card id="numberApply">
    <header>
      <h2 style="display: flex; align-items: center">
        公海号码申请
        <span v-if="roleName == '组长' && showTitleText">(申请客户名单，由管理员审核通过后，该号码将会到你的【个人私海】里面)</span>
        <span v-if="roleName == '人员' && showTitleText">(可在公司公海号码池中，申请自己想要的客户名单，申请后，由管理员审核通过后，号码会自动加入到你的【个人私海】中)</span>
        <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top">
          <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
            style="width: 30px; height: 30px;cursor: pointer;" @click="showTitleText = true" />
        </el-tooltip>
      </h2>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业名称</span>
            <el-input v-model="company" placeholder="请输入企业名称" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>客户号码</span>
            <el-input v-model="activePhone" placeholder="请输入客户号码" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>号码状态</span>
            <el-select v-model="p_status" placeholder="请选择状态" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>申请状态</span>
            <el-select v-model="f_status" placeholder="请选择状态" size="mini">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="left-wrap">
            <span>导入时间</span>
            <el-date-picker v-model="f_create_time" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
            </el-date-picker>
          </div> -->
        </div>
        <div class="right">
          <el-button type="success" icon="el-icon-search" @click="handleSearch" size="mini"
            class="searchBtn">查找</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="reset" size="mini" class="reset">重置</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="applyAll" size="mini" class="reset">批量申请</el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>
    <main>
      <el-table :data="tableData" border class="table" :class="filterEnterprise()" ref="multipleTable"
        header-cell-class-name="table-header" @selection-change="handleSelectionChange">
        <!-- 多选按钮 -->
        <el-table-column type="selection" width="55" align="center"></el-table-column><el-table-column prop="company"
          label="企业名称" align="center"></el-table-column>
        <el-table-column prop="active_phone" v-if="(!hideNum && id != 5051) || roleName == '企业'" label="客户号码"
          align="center"></el-table-column>
        <el-table-column prop="phone" label="客户号码" align="center"
          v-if="(hideNum || id == 5051) && roleName != '企业'"></el-table-column>

        <el-table-column prop="customer_phone" label="客户姓名" align="center"></el-table-column>
        <el-table-column prop="p_status" label="号码状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.p_status == 0">一般客户</span>
            <span v-if="scope.row.p_status == 1">意向客户</span>
            <span v-if="scope.row.p_status == 2">无意向客户</span>
            <span v-if="scope.row.p_status == 3">成交客户</span>
            <span v-if="scope.row.p_status == 4">黑名单</span>
          </template>
        </el-table-column>
        <el-table-column prop="f_status" label="申请状态" align="center">
          <template v-slot="scope">
            <div :class="fontClass(scope.row.f_status)">
              {{ statusClass(scope.row.f_status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template v-slot="scope">
            <el-button v-show="!distinguishStatus(scope.row.f_status)" type="success" plain :disabled="f_status !== null"
              @click="apply(scope.row)" size="mini">申 请</el-button>
            <el-button v-show="distinguishStatus(scope.row.f_status)" type="success" @click="apply(scope.row)" size="mini"
              style="margin-left: 0">申 请</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>
    <!-- 申请弹出框 -->
    <el-dialog title="申请" :visible.sync="applyVisible" width="30%">
      <span>确定申请这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="applyVisible = false">取 消</el-button>
          <el-button type="success" @click="submitApply()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量申请弹出框 -->
    <el-dialog title="批量申请" :visible.sync="applyAllVisible" width="30%">
      <span>确定申请这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="applyAllVisible = false">取 消</el-button>
          <el-button type="success" @click="submitApplyAll()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  phoneApplyForList,
  phoneApplyUserPhoneApplyFor,
} from "@/api/phoneApplyFor";
export default {
  data() {
    return {
      timer: null,
      hideNum: false,
      pickerOneDate: '',
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = '';
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围

          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 15 * 24 * 3600 * 1000 // 15天
            let maxTime = this.pickerOneDate + day
            let minTime = this.pickerOneDate - day
            return time.getTime() > maxTime || time.getTime() < minTime
          } else {
            // return time.getTime() > Date.now()
          }
        }
      },
      showTitleText: false,
      roleName: null,
      f_create_time: false,
      applyVisible: false,
      applyAllVisible: false,
      company: null,
      activePhone: null,
      p_status: null,
      f_status: null,
      numsArray: [],
      options: [
        {
          value: 0,
          label: "一般客户",
        },
        {
          value: 1,
          label: "意向客户",
        },
        {
          value: 2,
          label: "无意向客户",
        },
        {
          value: 3,
          label: "成交客户",
        },
        {
          value: 4,
          label: "黑名单",
        },
        {
          value: 5,
          label: "无状态",
        },
        {
          value: 6,
          label: "未接通",
        },
      ],
      options1: [
        {
          value: null,
          label: "可申请",
        },
        {
          value: 0,
          label: "申请待审核",
        },
        {
          value: 1,
          label: "申请通过",
        },
        {
          value: 2,
          label: "申请驳回",
        },
      ],
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.id = localStorage.getItem("id") * 1;
    this.roleName = localStorage.getItem("roleName");
    let bool = localStorage.getItem("hideNum");
    bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
    this.getList();
  },
  computed: {

  },
  methods: {
    // 筛选禁止复制的公司
    filterEnterprise() {
      // let arr = [2]
      let arr = [5805];
      if (arr.includes(localStorage.getItem("parentId") * 1)) {
        return "no-select";
      }
    },
    //转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 按钮状态
    distinguishStatus(f_status) {
      if (f_status == null || f_status == 2) {
        return true;
      }
    },
    // 状态分类
    statusClass(f_status) {
      // console.log("状态的值", f_status);
      if (f_status == null) {
        return "未申请";
      } else if (f_status == 0) {
        return "待审核";
      } else if (f_status == 1) {
        return "审核通过";
      } else if (f_status == 2) {
        return "审核未通过";
      }
    },
    // 状态分类字体样式
    fontClass(f_status) {
      // console.log("状态的值", f_status);
      if (f_status == null) {
        return "f_statusn";
      } else if (f_status == 0) {
        return "f_status0";
      } else if (f_status == 1) {
        return "f_status1";
      } else if (f_status == 2) {
        return "f_status2";
      }
    },
    // 申请
    apply(data) {
      let userId = JSON.parse(localStorage.getItem("id"));
      let params = {
        listId: [data.pid],
        userId,
      };
      this.applyParams = params;
      this.applyVisible = true;
    },
    // 确认申请
    submitApply() {
      let res = phoneApplyUserPhoneApplyFor(this.applyParams);
      res.then((res) => {
        Message.success(res.data.message);
        this.applyVisible = false;
        this.getList();
      });
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.pid);
      });
    },
    // 批量申请
    applyAll() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.applyAllVisible = true;
    },
    // 确认批量申请
    submitApplyAll() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.p_status == 4) {
            Message.warning("选中列表中包含黑名单，无法申请");
            return;
          }
        });
      });
      let userId = JSON.parse(localStorage.getItem("id"));
      let params = {
        listId: this.numsArray,
        userId,
      };
      let res = phoneApplyUserPhoneApplyFor(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.applyAllVisible = false;
      });
    },
    // 搜索
    handleSearch() {
      if (this.f_create_time != null) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.f_create_time[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.f_create_time[1]);
      }

      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.company = null;
      this.activePhone = null;
      this.p_status = null;
      this.f_status = null;
      this.f_create_time = null;
      this.getList();
    },
    // 展示
    getList() {

      let params = {
        company: this.company,
        activePhone: this.activePhone,
        p_status: this.p_status,
        f_status: this.f_status,
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
      };
      let res = phoneApplyForList(params);
      res.then((res) => {
        let data = res.data
        if (data.statusCode == '50000') {
          // this.cutIndex = oldIndex
          // data = res.data.data
          Message.warning(data.message);
          return false
        }

        if (data.data) {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }
        this.tableData.forEach((item) => {
          let str = item.active_phone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });

    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #FFF;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

.no-select {
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}

#numberApply {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 10px;
      align-items: center;
      // flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;

        .left-wrap {
          width: 24%;
          display: flex;
          align-items: center;

          .el-select {
            width: 100%;
          }

          .el-date-editor {
            width: 100% !important;
          }

          span {
            margin: 6px;
            width: 80px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }

    .f_statusn {
      color: #909399;
    }

    .f_status0 {
      color: #e6a23c;
    }

    .f_status1 {
      color: #67c23a;
    }

    .f_status2 {
      color: #f56c6c;
    }
  }
}
</style>